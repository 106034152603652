import { Button, message, Result } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import buyBasket from "@/core/basket/buyBasket";
import { setData, setOrderNote, setOrderReference } from "@/store/order";
import { setBasketList, setReload, toggle } from "@/store/basket";

const Confirm = () => {
  const { t } = useTranslation();
  const d = useDispatch();
  const order = useSelector(e => e.order);
  const basket = useSelector(e => e.basket)
  const tabsStore = useSelector(e => e.tabs)
  const tabList = tabsStore.tabs
  const buy = () => {


    d(setData({ key: 'section', value: 1 }))
    buyBasket(order.data.note, order.data.reference).then(e => {
      d(setBasketList([]))
      d(setData({ key: 'section', value: 2 }))
      d(setOrderNote(''))
      d(setOrderReference())
      setTimeout(() => {
        d(setData({ key: 'showModal', value: false }))
        d(setData({ key: 'section', value: 0 }))
        // d(toggle())
      }, 500)

    }).catch(f => {
      console.log(f)
      message.error(t("some-problem"));
      d(setData({ key: 'section', value: 0 }))
    })

  }

  return (
    <div>
      <Result
        status="info"
        title={t("your-orders-will-be-forwarded-to-the-store-do-you-approve")}
        subTitle=""
        extra={[
          <Button type="primary" onClick={() => buy()} key="console">
            {t("approve")}
          </Button>,
          <Button onClick={() => d(setData({ key: 'section', value: 0 }))} key="buy">
            {t("cancel")}
          </Button>,
        ]}
      />
    </div>
  );
};





const Processing = ({ onFinish }) => {
  const { t } = useTranslation();

  return <Result icon={<LoadingOutlined />} title={t("processing")} />;
};

const Success = () => {
  const { t } = useTranslation();

  return <Result status="success" title={t("successfully")} subTitle="Order number: 2017182818828182881." />;
};

const BuyModal = ({ onCancel, onFinish }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const order = useSelector(e => e.order)





  return <div>{order.data.section == 0 ? <Confirm /> : order.data.section == 1 ? <Processing /> : <Success />}</div>;
};

export default BuyModal;
