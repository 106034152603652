import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone'


const parseText = (timestamp, timezone) => {
  let hour = null;
  let diffDays = null;
  let givenDate = null;
  let day = null;
  try {
    const convert = moment.unix(timestamp).tz(timezone);
    const dateString = convert.format('YYYY-MM-DD HH:mm:ss');
    givenDate = moment.tz(dateString, timezone).startOf('day');
    const now = moment().tz(timezone).startOf('day');

    // Calculate the difference in days without considering hours
    diffDays = givenDate.diff(now, 'days');
    if (isNaN(diffDays)) diffDays = null;

    day = givenDate.weekday()
    hour = convert.format('HH:mm');
    if (hour === 'Invalid date') {
      hour = null;
    }

    if (givenDate.format() === 'Invalid date') {
      givenDate = null;
    } else {
      givenDate = givenDate.format('MM-DD HH:mm');
    }

    day = Number(day) ;
  } catch (e) {
    hour = null;
    diffDays = null;
    givenDate = null;
    day = null;
  }

  return { hour, diffDays, givenDate, day };
};


const useLogisticTimeToText = (timestamp) => {


    const {t} = useTranslation();
    const timezone = useSelector((e) => e.settings.timezone);
    const qs = useSelector((e) => e.settings);

    let {hour,diffDays,givenDate,day} = parseText(timestamp,timezone);
    if((!hour) || (!(isNaN(diffDays) || diffDays != null)) || !(givenDate)) return null;

    if(!timestamp) return null;


    let returnData = null;

    if (diffDays === 0) {
        returnData =  `${t('today')} ${hour}`;
      } else if (diffDays === 1) {
        returnData = `${t('tomorrow')} ${hour}`;
      } else if (diffDays > 1 && diffDays < 7) {
        
          if(day == 0){
            returnData = `${t('sunday')} ${hour}`;
          }else if (day == 1){
            returnData = `${t('monday')} ${hour}`;
          }
          else if (day == 2){
            returnData = `${t('tuesday')} ${hour}`;
          }
          else if (day == 3){
            returnData = `${t('wednesday')} ${hour}`;
          }
          else if (day == 4){
            returnData = `${t('thursday')} ${hour}`;
          }
          else if (day == 5){
            returnData = `${t('friday')} ${hour}`;
          }
          else if (day == 6){
            returnData = `${t('saturday')} ${hour}`;
          }
      } else {
          
        returnData = givenDate
      }


      return returnData


  // return (
  //   <div>useLogisticTimeToText</div>
  // )
}

export default useLogisticTimeToText