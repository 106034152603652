import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data : {
    section : 0,
    showModal:false
  }

}

export const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data[action.payload.key] = action.payload.value
    },
    setOrderNote: (state, action) => {
      state.data.note = action.payload
    },

    setOrderReference: (state, action) => {
      state.data.reference = action.payload
    }
    
  },
})

// Action creators are generated for each case reducer function
export const { setData,setOrderNote,setOrderReference} = order.actions

export default order.reducer

