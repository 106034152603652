import groupArray from 'group-array';
export default {
  cleanObj(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  },
  onTrimProductReference(ProductReference) {
    ProductReference = String(ProductReference);
    let t = ProductReference.replaceAll(" ", "");
    t = t.replaceAll("-", "");
    t = t.replaceAll("/", "");
    t = t.replaceAll(".", "");
    return t;
  },
  groupAvability(AvabilityInfoList, ProductReference, BrandId) {
    let tree = groupArray(AvabilityInfoList, 'SupplierId');
    let list = [];

    Object.keys(tree).forEach(supplierId => {
      let items = tree[supplierId]
      if (items.length == 0)
        return;
      let total = items.reduce((a, b) => a + parseFloat(b.CurrentStock), 0)
      let netPrice = items[0].NetPrice
      let grossPrice = items[0].GrossPrice;
      let supCode = items[0].SupplierId;
      let RowId = items[0].RowId;
      let ProductId = items[0].ProductId;
      let IsListed = items[0].IsListed;
      //let AvabilityList = items.filter(x => x.productSalesStatus);
      let MinimumOrderQuantity = items[0]?.TotalPiecesInPackage;
      list.push({
        SupplierId: supCode,
        CurrentStock: total,
        NetPrice: netPrice,
        GrossPrice: grossPrice,
        RowId: RowId,
        ProductId: ProductId,
        IsListed: IsListed,
        AvabilityList: items,
        productSaleStatus: items.filter(x => x.productSalesStatus).length > 0,
        MinimumOrderQuantity,
        ProductReference,
        BrandId
      })
    })
    return list;
  }

  
}