import React, { useState } from 'react'
import BasketIcon from '@/components/icons/BasketIcon'

import { useDispatch, useSelector } from 'react-redux'
import useIsAddedBasketByRowId from '@/hooks/useIsAddedBasketByRowId';
import { deleteBasketById, deleteBasketByProductReferenceAndSupplierId, insertNewBasket, updateStoreBasket } from '@/store/basket';

import { findStock, timestampToFullDate, getExistStockByStocks, calculateTotalStocksFromStocks, formatDateTime } from '@/core/avability/avability2'
import CounterButton from './CounterButton'
import LogisticTimeIcon from './LogisticTimeIcon'
import { message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import PhoneIcon from '@/components/icons/PhoneIcon';
import { useTranslation } from 'react-i18next';
import useCurrencyWithCode from '@/hooks/useCurrencyWithCode';


import { Tooltip } from "antd";

const getDesc = (description,language) => {
    let _description = description;
    let fullDescription = ""
    try {
      description = description && typeof description === "object"
        ? description[language?.toUpperCase()] || ""
        : "";

        fullDescription = _description && typeof _description === "object" 
        ? _description[language?.toUpperCase()] || ""
        : "";
  
      if (description.length > 30) {
        description = description.slice(0, 30) + "...";
      }
    } catch (error) {
    }
  
    return {description,fullDescription};
  }

const TableItem = ({ otherSeller, settings, timezone, deliveries, Plate, isAdmin, showGrossPrice }) => {
    const _hideNetPrice = useSelector((e => e.settings['hideNetPrice']));
    const hideNetPrice = _hideNetPrice === 'true' || _hideNetPrice === true;
    const formatedPrice = useCurrencyWithCode();

    const d = useDispatch();

    const price = otherSeller?.price;
    const rowId = otherSeller?.rowId;
    const supplierId = otherSeller?.supplierId;
    const stocks = otherSeller?.stocks;
    const isAddedBasketByRowId = useIsAddedBasketByRowId(rowId);
    const qty = isAddedBasketByRowId?.Quantity || 0;
    const activeStock = findStock(stocks, qty);
    const timestamp = activeStock?.logisticTime?.timestamp;
    const timestampToRegion = activeStock?.logisticTime?.timestampToRegion;

    const {description,fullDescription} = getDesc(otherSeller?.description, settings?.Language);


    const timestampToRegionDate = timestampToFullDate(timestampToRegion, timezone);
    const timestampDate = timestampToFullDate(timestamp, timezone);

    const deliveryStocks = getExistStockByStocks(stocks, deliveries);
    const totalStocks = calculateTotalStocksFromStocks(stocks);

    const [loadingBasket, setLoadingBasket] = useState(false);
    const minimumOrderQuantity = parseInt(otherSeller.TotalPiecesInPackage) || 1;

    const saleStatus = activeStock && price?.NetPrice;

    const PriceContent = () => {
        if (showGrossPrice && !hideNetPrice) {
            return (
                <React.Fragment>
                    <td className="inline-flex flex-col items-start min-w-[110px]">
                        <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                            {`${formatedPrice(price?.GrossPrice)}`}
                        </span>
                    </td>
                    <td className="inline-flex flex-col items-start min-w-[110px]">
                        <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                            {`${formatedPrice(price?.NetPrice)}`}
                        </span>
                    </td>
                </React.Fragment>
            );
        } else if (showGrossPrice && hideNetPrice) {
            return (
                <td className="inline-flex flex-col items-start min-w-[110px]">
                    <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                        {`${formatedPrice(price?.GrossPrice)}`}
                    </span>
                </td>
            );
        } else {
            return (
                <td className="inline-flex flex-col items-start min-w-[110px]">
                    <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                        {`${formatedPrice(price?.NetPrice)}`}
                    </span>
                </td>
            );
        }
    };

    const addToBasket = () => {
        setLoadingBasket(true);
        try {
            return d(insertNewBasket({
                quantity: minimumOrderQuantity,
                RowId: rowId,
                Plate: Plate,
                cb: (err) => {
                    console.log(err);
                    setLoadingBasket(false);
                }
            }));
        } catch (e) {
            console.log(e);
        }
        setLoadingBasket(false);
    };

    const updateBasketHandler = (quantity) => {
        if (quantity < minimumOrderQuantity) {
            setLoadingBasket(true);
            d(deleteBasketById({
                basketId: isAddedBasketByRowId?.Id,
                cb: () => {
                    setLoadingBasket(false);
                }
            }));
        } else {
            setLoadingBasket(true);
            d(updateStoreBasket({
                basketId: isAddedBasketByRowId?.Id,
                Quantity: quantity,
                cb: () => {
                    setLoadingBasket(false);
                }
            }));
        }
    };

    const onChangeQty = (type, value) => {
        if (type === 'decrease') {
            updateBasketHandler(parseInt(qty) - parseInt(minimumOrderQuantity));
        } else if (type === 'increment') {
            updateBasketHandler(parseInt(qty) + parseInt(minimumOrderQuantity));
        } else if (type === 'change') {
            if (parseInt(value) % minimumOrderQuantity !== 0) {
                return message.error('Invalid value');
            }
            updateBasketHandler(parseInt(value));
        }
    };

    return (
        <tr className="flex items-center justify-between p-[10px] py-1">
            <td className="inline-flex flex-col items-start min-w-[110px]">
                <Tooltip title={fullDescription}>
                    <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                        {description}
                    </span>
                </Tooltip>
            </td>
            {isAdmin && (
                <React.Fragment>
                    <td className="inline-flex flex-col items-start min-w-[110px]">
                        <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                            {supplierId}
                        </span>
                    </td>
                    <td className="inline-flex flex-col items-start min-w-[110px]">
                        <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                            {activeStock?.WarehouseName}
                        </span>
                    </td>
                </React.Fragment>
            )}
            <PriceContent />
            <td className="inline-flex flex-col items-start min-w-[110px]">
                <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                    {deliveryStocks}
                </span>
            </td>
            <td className="inline-flex flex-col items-start min-w-[110px]">
                <span className="[font-family:'Poppins-Regular',Helvetica] font-normal text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                    {totalStocks}
                </span>
            </td>
            <td className="inline-flex flex-col items-start min-w-[284px]">
                <LogisticTimeIcon logisticTime={timestamp} logisticTimeToRegion={timestampToRegion} />
            </td>
            {saleStatus ? (
                isAddedBasketByRowId ? (
                    <td className="inline-flex flex-col items-center justify-around rounded-[6px] min-w-[125px]">
                        <CounterButton value={parseInt(qty)} onChange={onChangeQty} />
                    </td>
                ) : (
                    <td
                        onClick={addToBasket}
                        className="inline-flex flex-col items-center justify-around p-[5px] rounded-[6px] min-w-[125px] cursor-pointer"
                        style={{ backgroundColor: settings?.['HeaderBgColor'] }}
                    >
                        {loadingBasket ? (
                            <LoadingOutlined style={{ color: 'white', fontSize: '24px' }} />
                        ) : (
                            <BasketIcon className="w-[24px] h-[24px]" />
                        )}
                    </td>
                )
            ) : (
                <td
                    style={{ backgroundColor: "rgb(164 164 164)" }}
                    className="inline-flex flex-col items-center justify-around p-[8px] rounded-[6px] min-w-[125px] disabled"
                >
                    <PhoneIcon />
                </td>
            )}
        </tr>
    );
};


const OtherSellers = ({ otherSellers, deliveries = [] }) => {
    const settings = useSelector(e => e.settings)
    const timezone = useSelector((e) => e.settings.timezone)
    const showGrossPrice = useSelector(e => e.settings.ShowGrossPrice) == 'true' ? true : false
    const isAdmin = (useSelector((e) => e?.session?.account?.Role) == 1) ? true : false

    const hideNetPrice = useSelector((e => e.settings['hideNetPrice'])) ? true : false


    const { t } = useTranslation()
    return (
        <React.Fragment>
            <table className="w-full">
                <thead className='bg-[#f6f6f6] rounded-[6px]'>
                    <tr className="flex items-center justify-between p-[10px]">
                        <th className="flex flex-col min-w-[110px] items-start">
                            <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">
                                {t('description')}
                            </span>
                        </th>

                        {isAdmin && <React.Fragment>
                            <th className="flex flex-col min-w-[110px] items-start">
                                <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">{t('supplier')}</span>
                            </th>
                            <th className="flex flex-col min-w-[110px] items-start">
                                <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">{t('warehouse')}</span>
                            </th>
                        </React.Fragment>}

                        {(showGrossPrice) && <th className="flex flex-col min-w-[110px] items-start">
                            <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">{t('gross-price')}</span>
                        </th>}
                        {(!hideNetPrice) && <th className="flex flex-col min-w-[110px] items-start">
                            <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">{t('net-price')}</span>
                        </th>}

                        {/* <th className="flex flex-col min-w-[110px] items-start">
                            <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">Depot Aantal</span>
                        </th> */}
                        <th className="flex flex-col min-w-[110px] items-start">
                            <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">{t('own-stock')}</span>
                        </th>
                        <th className="flex flex-col min-w-[110px] items-start">
                            <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">{t('total') + ' ' + t('stock')}</span>
                        </th>
                        <th className="inline-flex flex-col min-w-[284px] items-start">
                            <span className="[font-family:'Poppins-Medium',Helvetica] font-medium text-[#616161] text-[14px] tracking-[0] leading-[normal]">{t('route')}</span>
                        </th>
                        <th className="flex flex-col w-[125px] items-start"></th>
                    </tr>
                </thead>
                <tbody>
                    {otherSellers.map((item, index) => (<TableItem deliveries={deliveries} isAdmin={isAdmin} showGrossPrice={showGrossPrice} timezone={timezone} otherSeller={item} settings={settings} key={index} />))}
                </tbody>
            </table>
        </React.Fragment >
    )
}

export default OtherSellers