import basket, { updateStoreBasket, deleteBasketById } from '@/store/basket'
import { InputNumber, message, Skeleton, Spin } from 'antd'
import NumberRound from 'number-zero-round'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TrashIcon from '@/extra/TrashIcon'
import LogisticIcon2 from './BasketLogisticIcon'
import ProductPrice from '@/components/products/BuyArea/ProductPrice'
import useCurrencyWithCode from '@/hooks/useCurrencyWithCode'
import { findStock, getFastestAvability } from '@/core/avability/avability2'
import useLogisticTimeToText from '@/hooks/useLogisticTimeToText'
import QuestionIcon from '../icons/QuestionIcon'
import BoxIcon from '../icons/BoxIcon'
import GarageIcon from '../icons/GarageIcon'

const BasketItemCollpaseItem = ({ basketItem }) => {
  const d = useDispatch();
  const { t } = useTranslation()

  const deliveries = useSelector(e => e.basket.deliveries)
  const showGrossPrice = useSelector(e => e.settings.ShowGrossPrice) == 'true' ? true : false


  const row = getFastestAvability(basketItem?.prices, deliveries)
  const supplierId = row?.supplierId
  const stocks = row?.stocks
  const MinimumOrderQuantity = Number(basketItem?.TotalPiecesInPackage) || 1
  const hideNetPrice = useSelector((e => e.settings['hideNetPrice'])) ? true : false
  const formatedPrice = useCurrencyWithCode()


  const price = row?.price

  const netPrice = price?.NetPrice;
  const grossPrice = price?.GrossPrice


  const qty = Number(basketItem.Quantity)

  const activeStock = findStock(stocks, (qty || 0)); //şuanki depodan geliş zamanı etc.

  const isExist = deliveries.includes(activeStock?.WarehouseId) ? true : false

  const timestamp = activeStock?.logisticTime?.timestamp
  const timestampToRegion = activeStock?.logisticTime?.timestampToRegion

  const logisticTimeText = useLogisticTimeToText(timestamp)
  const logisticTimeToRegionText = useLogisticTimeToText(timestampToRegion)




  const [route, setRoute] = useState(null);
  const [loadingQuantity, setLoadingQuantity] = useState(false);

  const update = (quantity) => {
    if (quantity < MinimumOrderQuantity) {
      setLoadingQuantity(true)
      return d(deleteBasketById({
        basketId: basketItem.Id, cb: () => {
          setLoadingQuantity(false)

        }
      }))
    }
    setLoadingQuantity(true)
    d(updateStoreBasket({
      basketId: basketItem.Id,
      Quantity: quantity,
      cb: () => {
        setLoadingQuantity(false);
      }
    }))
  }

  const destroy = () => {
    setLoadingQuantity(true)
    return d(deleteBasketById({
      cb: () => {
        setLoadingQuantity(false)
      }, basketId: basketItem.Id
    }))
  }




  const PriceAreBasket = () => {

    if (showGrossPrice && hideNetPrice == false) {

      return (netPrice && netPrice
        ? <div>
          <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#676767] text-[15px] text-right tracking-[0] leading-[normal] whitespace-nowrap">
            {formatedPrice(netPrice)}
          </div>
          <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#bbbbbb] text-[11px] text-right tracking-[0] leading-[normal] line-through whitespace-nowrap">
            {showGrossPrice && formatedPrice(grossPrice)}
          </div>
        </div>
        : "€0.00")



    }
    else if (showGrossPrice && hideNetPrice == true) {
      return (
        grossPrice && grossPrice
          ? <div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#676767] text-[15px] text-right tracking-[0] leading-[normal] whitespace-nowrap">
            {formatedPrice(grossPrice)}
          </div>
          : "€0.00"
      )

    }

    else {
      //sadece net price göster
      return (<div className="relative w-fit mt-[-1.00px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#676767] text-[15px] text-right tracking-[0] leading-[normal] whitespace-nowrap">
        {formatedPrice(netPrice)}
      </div>)
    }

  }




  if (!row)
    return null



  return (
    <Skeleton active={true} loading={false}>
      {/* <div className="basket-item-collapse-item ">
        <div className="basket-item-collapse-item__supplier-id">
          {SupplierId}
        </div>
        <div className="basket-item-collapse-item__logistic">
          <LogisticIcon2 route={route} />
        </div>
        <div className="basket-item-collapse-item__input">
          <Spin spinning={loadingQuantity}>
            <InputNumber step={MinimumOrderQuantity} onChange={update} value={basketItem?.Quantity} />
          </Spin>

        </div>
        {fastestAvability && fastestAvability?.NetPrice
          ? <div className="basket-item-collapse-item__price">
            <ProductPrice GrossPrice={NumberRound(fastestAvability?.GrossPrice)} NetPrice={NumberRound(fastestAvability?.NetPrice)} Type={4} />
          </div> : null}
        <div className="basket-item-collapse-item__trash">
          <TrashIcon onClick={destroy} />
        </div>
      </div> */}


      <div className="flex items-center justify-between px-[10px] py-[5px] relative bg-[#f8f8f8] rounded-[6px] overflow-hidden">
        <div className="inline-flex items-center gap-[10px] relative self-stretch flex-[0_0_auto]">
          <div className="relative min-w-[20px] [font-family:'Poppins-Regular',Helvetica] font-normal text-[#999999] text-[12px] tracking-[0] leading-[normal] flex items-center">
            {supplierId}
          </div>
          <div className="flex flex-row items-start justify-around relative self-stretch flex-[0_0_auto] gap-[10px] items-center">
            {isExist ?
              (
                <>
                  <div className="flex items-center justify-center">
                    <BoxIcon onClick={destroy} />
                  </div>
                  <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#0da487] text-[13px] tracking-[0] leading-[normal]">
                    Direct
                  </div>
                </>
              )
              :
              (<>
                <div className="flex items-center justify-center">
                  <GarageIcon onClick={destroy} color="#1C9AE1" />
                </div>
                {logisticTimeToRegionText ? <div className="relative w-fit [font-family:'Poppins-Regular',Helvetica] font-normal text-[#1C9AE1] text-[13px] tracking-[0] leading-[normal]">
                  {logisticTimeToRegionText}
                </div> : "........."}
              </>
              )
            }

          </div>
        </div>
        <div className="inline-flex items-center gap-[10px] relative flex-[0_0_auto]">
          <div className="flex w-[85px] items-center justify-between relative rounded-[6px] border border-solid border-[#efefef]">
            <Spin spinning={loadingQuantity}>
              <InputNumber step={MinimumOrderQuantity} onChange={update} value={basketItem?.Quantity} />
            </Spin>
          </div>
          <div className="inline-flex flex-col items-end justify-between p-[10px] relative self-stretch flex-[0_0_auto]">
            <PriceAreBasket />

          </div>
          <div style={{ marginLeft: '-10px' }}>
            <TrashIcon onClick={destroy} />
          </div>
        </div>
      </div>
    </Skeleton>
  )
}

export default BasketItemCollpaseItem
